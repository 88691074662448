import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FiMenu, FiX, FiHome, FiCpu, FiDollarSign, FiBook, FiSettings, FiHelpCircle } from 'react-icons/fi';
import EdersparkLogo from "../assets/EdersparkLogoLargeInverted.png";

const PlatformNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { icon: <FiHome size={20} />, label: 'Overview', path: '/platform' },
    { icon: <FiCpu size={20} />, label: 'API Usage', path: '/platform/usage' },
    { icon: <FiDollarSign size={20} />, label: 'Billing', path: '/platform/billing' },
    { icon: <FiBook size={20} />, label: 'Documentation', path: '/platform/docs' },
    { icon: <FiSettings size={20} />, label: 'Settings', path: '/platform/settings' },
  ];

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        scrolled ? 'bg-black/80 backdrop-blur-lg shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src={EdersparkLogo}
              alt="Ederspark Logo"
              className="h-8 cursor-pointer"
              onClick={() => navigate('/home')}
            />
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            {navItems.map((item, index) => (
              <motion.button
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(item.path)}
                className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white rounded-lg hover:bg-gray-800/50 transition-all duration-300"
              >
                {item.icon}
                <span>{item.label}</span>
              </motion.button>
            ))}

            {/* Help Button */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="ml-2 p-2 text-gray-300 hover:text-white rounded-full hover:bg-gray-800/50 transition-all duration-300"
              onClick={() => navigate('/platform/help')}
            >
              <FiHelpCircle size={20} />
            </motion.button>

            {/* User Profile */}
            <div className="ml-4 flex items-center">
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="flex items-center gap-3 px-4 py-2 bg-orange-500/10 text-orange-400 rounded-lg cursor-pointer hover:bg-orange-500/20 transition-all duration-300"
              >
                <div className="w-8 h-8 rounded-full bg-orange-500/20 flex items-center justify-center">
                  <span className="text-sm font-medium">JS</span>
                </div>
                <span className="font-medium">John Smith</span>
              </motion.div>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-800/50 transition-all duration-300"
            >
              {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </motion.button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="md:hidden bg-black/95 backdrop-blur-lg border-t border-gray-800"
        >
          <div className="px-4 py-2 space-y-1">
            {navItems.map((item, index) => (
              <motion.button
                key={index}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  navigate(item.path);
                  setIsOpen(false);
                }}
                className="w-full flex items-center gap-3 px-4 py-3 text-gray-300 hover:text-white rounded-lg hover:bg-gray-800/50 transition-all duration-300"
              >
                {item.icon}
                <span>{item.label}</span>
              </motion.button>
            ))}
            
            {/* Mobile Help Button */}
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                navigate('/platform/help');
                setIsOpen(false);
              }}
              className="w-full flex items-center gap-3 px-4 py-3 text-gray-300 hover:text-white rounded-lg hover:bg-gray-800/50 transition-all duration-300"
            >
              <FiHelpCircle size={20} />
              <span>Help</span>
            </motion.button>

            {/* Mobile User Profile */}
            <div className="px-4 py-3 mt-2 border-t border-gray-800">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-orange-500/20 flex items-center justify-center text-orange-400">
                  <span className="text-sm font-medium">JS</span>
                </div>
                <span className="text-orange-400 font-medium">John Smith</span>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.nav>
  );
};

export default PlatformNavbar; 