import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "../components/Footer.jsx";
import { motion } from "framer-motion";
import { FaGraduationCap, FaChartLine, FaUsers, FaLightbulb } from "react-icons/fa";
import { RiTimerFlashLine } from "react-icons/ri";
import DemoRequestModal from "../components/DemoRequestModal";

const UniversitiesPage = ({ activeLink, setActiveLink }) => {
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

  return (
    <div className="bg-gray-900 w-full">
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="pt-24 px-4 md:px-12 lg:px-24 pb-12 relative"
      >
        {/* Glowing background effect */}
        <div className="absolute bottom-0 left-[50%] transform -translate-x-1/2 w-[800px] h-[800px] opacity-20 bg-orange-500 rounded-full blur-[128px]" />

        <div className="max-w-7xl mx-auto relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h1 className="text-5xl md:text-7xl lg:text-8xl font-bold tracking-normal mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-600 animate-shimmer bg-[size:200%_100%]">
                Transform
              </span>
              <span className="text-white"> Academic Research with </span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-600 animate-shimmer bg-[size:200%_100%]">
                AI
              </span>
            </h1>
            <p className="text-xl text-gray-400 max-w-3xl mx-auto">
              Empower your students and faculty with cutting-edge AI tools designed specifically for academic excellence
            </p>
          </motion.div>

          {/* Benefits Grid */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
          >
            {[
              {
                icon: <FaGraduationCap className="w-8 h-8" />,
                title: "Enhanced Learning Experience",
                description: "Help students quickly understand complex research papers and academic concepts"
              },
              {
                icon: <FaChartLine className="w-8 h-8" />,
                title: "Accelerated Research",
                description: "Enable faculty to conduct literature reviews and research faster than ever"
              },
              {
                icon: <FaUsers className="w-8 h-8" />,
                title: "Institutional Access",
                description: "Provide your entire academic community with powerful research tools"
              },
              {
                icon: <RiTimerFlashLine className="w-8 h-8" />,
                title: "Time Efficiency",
                description: "Save countless hours in research and paper analysis"
              },
              {
                icon: <FaLightbulb className="w-8 h-8" />,
                title: "Innovation Catalyst",
                description: "Foster groundbreaking research and academic excellence"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 * index }}
                className="bg-gray-800 p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="text-emerald-400 mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-bold text-gray-100 mb-2">{benefit.title}</h3>
                <p className="text-gray-300">{benefit.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center bg-gray-800/50 backdrop-blur-lg border border-gray-700 p-12 rounded-2xl mb-16"
          >
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Transform Your Institution's Research Capabilities?
            </h2>
            <p className="text-xl text-gray-300 mb-8">
              Join leading universities in revolutionizing academic research with EderSpark
            </p>
            <motion.button
              onClick={() => setIsDemoModalOpen(true)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center justify-center bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white px-10 py-3 rounded-full text-lg font-semibold transition-all duration-300 shadow-lg hover:shadow-orange-500/50 relative overflow-hidden group"
            >
              <motion.span 
                className="absolute inset-0 bg-gradient-to-r from-orange-400/20 to-transparent"
                animate={{
                  x: ["100%", "-100%"]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
              <span className="mr-3 relative z-10 group-hover:translate-x-1 transition-transform duration-200">
                Schedule a Demo
              </span>
              <motion.svg 
                className="w-5 h-5 relative z-10"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
                animate={{ 
                  x: [0, 5, 0],
                  scale: [1, 1.1, 1]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 1.5,
                  ease: "easeInOut"
                }}
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </motion.svg>
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
      
      <DemoRequestModal 
        isOpen={isDemoModalOpen}
        onClose={() => setIsDemoModalOpen(false)}
      />
    </div>
  );
};

export default UniversitiesPage; 