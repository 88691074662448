import { FaTiktok } from "react-icons/fa";
import { SiGithub, SiLinkedin, SiX } from 'react-icons/si'
import { AiFillInstagram } from "react-icons/ai";
import { Copyright } from './Copyright.tsx'
import EdersparkLogo from "../assets/EdersparkLogoLargeInverted.png";

const Footer = () => (
  <footer className="py-10 md:py-12 bg-black px-10">
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-between items-center">
        <img src={EdersparkLogo} alt="Ederspark Logo" width='100px' />
        <div className="flex gap-4">
          {socialLinks.map(({ href, icon }, index) => (
            <a 
              key={index} 
              href={href} 
              className="text-white hover:text-orange-500 transition-all duration-300"
            >
              <div className="w-5 h-5">
                {icon}
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full justify-center gap-6 text-xs md:text-base">
          <a
            className="text-gray-400 hover:text-orange-500 hover:underline transition-all duration-300"
            href="/terms"
          >
            Terms of Service
          </a>
          <a
            className="text-gray-400 hover:text-orange-500 hover:underline transition-all duration-300"
            href="/cookie-policy"
          >
            Cookie Policy
          </a>
          <a
            className="text-gray-400 hover:text-orange-500 hover:underline transition-all duration-300"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          <a
            className="text-gray-400 hover:text-orange-500 hover:underline transition-all duration-300"
            href="/disclaimer"
          >
            Legal Disclaimer
          </a>
      </div>
      <Copyright />
    </div>
  </footer>
)

const socialLinks = [
  { href: 'https://x.com/EderSpark', icon: <SiX /> },
  { href: 'https://www.tiktok.com/@ederspark', icon: <FaTiktok /> },
  { href: 'https://www.linkedin.com/company/ederspark', icon: <SiLinkedin /> },
  { href: 'https://www.instagram.com/ederspark/', icon: <AiFillInstagram /> },
]

export default Footer