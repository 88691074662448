import React from "react";
import { Box, Typography, Button, Stack } from "@mui/joy";
import { motion } from "framer-motion"; // For animations
import Navbar from "./Navbar.jsx";
import Footer from "../components/Footer.jsx";

const MotionTypography = motion(Typography); // Motion applied to Typography

const Landing = ({ activeLink, setActiveLink }) => {
  return (
    <div
      className="bg-gray-50"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />

      {/* Main Content */}
      <div
        className="flex flex-col justify-center items-center min-h-[80vh] px-4 text-center"
      >
        {/* Heading with Highlight */}
        <div
          className="flex flex-col space-y-4 items-center gap-3"
        >
          <motion.div
            className="text-4xl font-semibold text-gray-700"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Welcome to{" "}
            <text
              className="text-emerald-500 font-bold"
            >
              EderSpark
            </text>{" "}
            AI Platform
          </motion.div>

          {/* Subheading */}
          <motion.div
            className="text-lg text-gray-600"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <text
              className="text-emerald-500 font-bold"
            >
              Enhance
            </text>{" "}
            your research process with our{" "}
            <text
              className="text-emerald-500 font-bold"
            >
              AI-powered
            </text>{" "}
            tools.
          </motion.div>

          {/* Call-to-Action Buttons */}
          <motion.div
            className="flex flex-col md:flex-row gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <button
              className="text-white bg-emerald-400 hover:bg-emerald-200 px-4 py-2 w-48 rounded-md shadow-lg font-bold mb-4 md:mb-0"
              onClick={() => window.open("https://sparkai.ederspark.com/signup", "_self")}
            >
              Sign Up
            </button>
            <button
              className="text-emerald-500 bg-white hover:bg-gray-100 px-4 py-2 w-48 rounded-md shadow-lg font-bold border-2 border-emerald-500"
              onClick={() => window.open("https://sparkai.ederspark.com/signin", "_self")}
            >
              Sign In
            </button>
          </motion.div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landing;
