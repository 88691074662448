import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@chakra-ui/react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

import EdersparkLogo from "../assets/EdersparkLogoLargeInverted.png";
import { PiStudent } from "react-icons/pi";
import { SlChemistry } from "react-icons/sl";
import { LuBuilding } from "react-icons/lu";
import { SiX } from "react-icons/si";
import { LuBookOpen } from "react-icons/lu";

import { BeakerIcon } from "@heroicons/react/24/solid";

import DemoRequestModal from "../components/DemoRequestModal";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const currentPath = window.location.pathname;
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

  useEffect(() => {
    let timeoutId;
    
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrolled = currentScrollY > 0;
      
      if (currentScrollY < lastScrollY) {
        // Scrolling up - show immediately
        setVisible(true);
      } else if (currentScrollY > lastScrollY && currentScrollY > 80) {
        // Scrolling down - hide immediately
        setVisible(false);
        
        // After 1.5 seconds, show the navbar again
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setVisible(true);
        }, 1500);
      }
      
      setScrolled(isScrolled);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [lastScrollY]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateTo = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={`w-full h-auto flex items-center justify-center backdrop-blur-md text-white fixed top-0 z-50 transition-all duration-300 transform ${
          visible ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        {/* Navbar */}
        <nav className="flex flex-row w-11/12 items-center justify-between flex-wrap p-4">
          {/* Left section with logo and main nav */}
          <div className="flex items-center">
            {/* Logo */}
            <img 
              onClick={() => navigateTo("/home")}
              className="cursor-pointer w-36 mr-8"
              src={EdersparkLogo} 
              alt="Ederspark Logo" 
            />

            {/* Main Navigation Links */}
            <ul className="hidden md:flex gap-6 list-none items-center">
              <li
                className={`cursor-pointer hover:text-orange-500 transition-all duration-300 ${
                  currentPath === "/home" ? "font-bold text-orange-500" : ""
                }`}
                onClick={() => navigateTo("/home")}
              >
                Home
              </li>

              {/* Our tools dropdown */}
              <li className="relative group">
                <div className={`cursor-pointer hover:text-orange-500 flex items-center`}>
                  Our Tools
                  <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                <ul className="absolute hidden group-hover:block bg-gray-900 border border-gray-800 rounded-md shadow-lg py-2 w-56 z-50 text-sm">
                  <li
                    className="px-4 py-2 hover:bg-gray-800 cursor-pointer flex items-center gap-2"
                    onClick={() => navigateTo("/universities")}
                  >
                    <PiStudent className="h-5 w-5" />
                    For Universities
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-800 cursor-pointer flex items-center gap-2"
                    onClick={() => navigateTo("/research-centers")}
                  >
                    <SlChemistry className="h-5 w-5" />
                    For Research Centers
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-800 cursor-pointer flex items-center gap-2"
                    onClick={() => navigateTo("/enterprises")}
                  >
                    <LuBuilding className="h-5 w-5" />
                    For Enterprises
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-800 cursor-pointer flex items-center gap-2"
                    onClick={() => navigateTo("/how-it-works")}
                  >
                    <LuBookOpen className="h-5 w-5" />
                    How It Works
                  </li>
                </ul>
              </li>

              <li
                className={`cursor-pointer hover:text-orange-500 ${
                  currentPath === "/pricing" ? "font-bold text-orange-500" : ""
                }`}
                onClick={() => navigateTo("/pricing")}
              >
                Pricing
              </li>

              <li
                className={`cursor-pointer hover:text-orange-500 ${
                  currentPath === "/about" ? "font-bold text-orange-500" : ""
                }`}
                onClick={() => navigateTo("/about")}
              >
                About
              </li>
            </ul>
          </div>

          {/* Right section with CTA buttons */}
          <div className="hidden md:flex items-center gap-4">
            <button
              onClick={() => navigateTo("/platform")}
              className="px-4 py-2 text-white hover:text-orange-500 transition-all duration-300 hover:scale-105"
            >
              API Platform
            </button>
            <button
              onClick={() => setIsDemoModalOpen(true)}
              className="px-4 py-2 text-white hover:text-orange-500 transition-all duration-300 hover:scale-105"
            >
              Request a Demo
            </button>
            <button
              onClick={() => window.open("https://sparkai.ederspark.com/login", "_blank")}
              className="px-4 py-2 text-white hover:text-orange-500 transition-all duration-300 hover:scale-105"
            >
              Sign In
            </button>
            <button
              onClick={() => window.open("https://sparkai.ederspark.com/signup", "_blank")}
              className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-all duration-300 hover:scale-105 flex items-center gap-2"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="currentColor" 
                className="w-5 h-5"
              >
                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
              </svg>
              Try Our Chat
            </button>
          </div>

          {/* Mobile menu button */}
          <IconButton
            className="block md:hidden ml-auto text-white"
            onClick={toggleMenu}
            variant="ghost"
            aria-label="Toggle Navigation"
          >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </IconButton>

          {/* Mobile Menu */}
          {isOpen && (
            <div className="md:hidden fixed inset-0 top-[70px] bg-gray-900/95 backdrop-blur-md z-40 h-screen">
              <div className="flex flex-col justify-between h-full">
                <ul className="flex flex-col p-4 space-y-4">
                  <li
                    className={`cursor-pointer hover:text-orange-500 ${
                      currentPath === "/home" ? "font-bold text-orange-500" : ""
                    }`}
                    onClick={() => {
                      navigateTo("/home");
                      setIsOpen(false);
                    }}
                  >
                    Home
                  </li>

                  {/* Mobile Our Tools Section */}
                  <li className="space-y-2">
                    <div className={`cursor-pointer hover:text-orange-500`}>
                      Our Tools
                    </div>
                    <ul className="pl-4 space-y-2">
                      <li
                        className="flex items-center gap-2 py-2"
                        onClick={() => {
                          navigateTo("/universities");
                          setIsOpen(false);
                        }}
                      >
                        <PiStudent className="h-5 w-5" />
                        For Universities
                      </li>
                      <li
                        className="flex items-center gap-2 py-2"
                        onClick={() => {
                          navigateTo("/research-centers");
                          setIsOpen(false);
                        }}
                      >
                        <SlChemistry className="h-5 w-5" />
                        For Research Centers
                      </li>
                      <li
                        className="flex items-center gap-2 py-2"
                        onClick={() => {
                          navigateTo("/enterprises");
                          setIsOpen(false);
                        }}
                      >
                        <LuBuilding className="h-5 w-5" />
                        For Enterprises
                      </li>
                      <li
                        className="flex items-center gap-2 py-2"
                        onClick={() => {
                          navigateTo("/how-it-works");
                          setIsOpen(false);
                        }}
                      >
                        <LuBookOpen className="h-5 w-5" />
                        How It Works
                      </li>
                    </ul>
                  </li>

                  {/* Other Mobile Menu Items */}
                  <li
                    className={`cursor-pointer hover:text-orange-500 ${
                      currentPath === "/pricing" ? "font-bold text-orange-500" : ""
                    }`}
                    onClick={() => {
                      navigateTo("/pricing");
                      setIsOpen(false);
                    }}
                  >
                    Pricing
                  </li>

                  <li
                    className={`cursor-pointer hover:text-orange-500 ${
                      currentPath === "/about" ? "font-bold text-orange-500" : ""
                    }`}
                    onClick={() => {
                      navigateTo("/about");
                      setIsOpen(false);
                    }}
                  >
                    About
                  </li>

                  <li
                    className="cursor-pointer hover:text-orange-500"
                    onClick={() => {
                      navigateTo("/platform");
                      setIsOpen(false);
                    }}
                  >
                    API Platform
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </div>

      {/* Add the DemoRequestModal component */}
      <DemoRequestModal 
        isOpen={isDemoModalOpen}
        onClose={() => setIsDemoModalOpen(false)}
      />
    </>
  );
};

export default Navbar;
