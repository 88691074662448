import React from "react";
import Navbar from "../pages/Navbar.jsx";
import { Box, Typography, Button } from "@mui/joy";
import HeroSection from "../components/HeroSection.jsx"; 
import TrustedLogos from "../components/TrustedLogosSection.jsx";
import ProductSection from "../components/ProductSection.jsx";
import HowItWorksSection from "../components/HowItWorksSection.jsx";
import KeyFeatures from "../components/KeyFeaturesSection.jsx";
import WhyChooseUs from "../components/WhyChooseUsSection.jsx";
import Footer from "../components/Footer.jsx";
import ToolsSection from "../pages/ToolsSection.jsx";
import ChatbotBadge from "../components/ChatbotBadge.jsx";
import { motion } from "framer-motion";
import ExamplesSection from "../components/ExamplesSection.jsx";
import FAQSection from "../components/FAQSection.jsx";
import PricingSection from "../components/PricingSection.jsx";
import ModelComparisonSection from "../components/ModelComparisonSection.jsx";
import ModelTiersSection from "../components/ModelTiersSection.jsx";
import ProblemsSection from "../components/ProblemsSection.jsx";


const Home = ({ activeLink, setActiveLink }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-black pt-24 overflow-x-hidden"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      <HeroSection />
      <ProblemsSection />
      <ProductSection />
      <ExamplesSection />
      <ModelComparisonSection />
      <ModelTiersSection />
      <FAQSection />
      <TrustedLogos />
      <Footer />
    </motion.div>
  );
};

export default Home;
