import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "../components/Footer";
const PlatformPricing = () => {
  const navigate = useNavigate();

  const models = [
    {
      name: "Freiya Mini",
      description: "Perfect for quick answers and basic research tasks",
      icon: <i className="fas fa-bolt text-gray-300"></i>,
      color: "text-gray-300",
      bgColor: "bg-gray-900/50",
      pricing: [
        { type: "Input", price: "0.27€", notes: "Per call" },
        { type: "Context Window", value: "4096 tokens", notes: "Maximum input size" },
        { type: "Response Time", value: "~23s", notes: "Average" },
      ],
      features: [
        "Fast response times",
        "Basic research capabilities",
        "Cost-effective solution",
        "Ideal for simple queries"
      ]
    },
    {
      name: "SparkAI Focus",
      description: "Specialized for targeted article analysis",
      icon: <i className="fas fa-bullseye text-purple-300"></i>,
      color: "text-purple-300",
      bgColor: "bg-purple-900/50",
      pricing: [
        { type: "Input", price: "0.025€", notes: "Per call" },
        { type: "Context Window", value: "4096 tokens", notes: "Maximum input size" },
        { type: "Response Time", value: "~9s", notes: "Average" },
      ],
      features: [
        "Paper-specific analysis",
        "Enhanced accuracy",
        "Rapid processing",
        "Citation handling"
      ],
      popular: true
    },
    {
      name: "SparkAI Pro",
      description: "Advanced capabilities for comprehensive research",
      icon: <i className="fas fa-star text-green-300"></i>,
      color: "text-green-300",
      bgColor: "bg-green-900/50",
      pricing: [
        { type: "Input", price: "0.52€", notes: "Per call" },
        { type: "Context Window", value: "4096 tokens", notes: "Maximum input size" },
        { type: "Response Time", value: "~38s", notes: "Average" },
        { type: "Citations", value: "Yes", notes: "" },
      ],
      features: [
        "Comprehensive research",
        "Advanced analysis",
        "Multiple source integration",
        "Enhanced accuracy"
      ]
    },
    {
      name: "SparkAI Nova",
      description: "Superior reasoning and deep analytical capabilities",
      icon: <i className="fas fa-crown text-yellow-300"></i>,
      color: "text-yellow-300",
      bgColor: "bg-yellow-900/50",
      pricing: [
        { type: "Input", price: "0.71€", notes: "Per call" },
        { type: "Context Window", value: "8192 tokens", notes: "Maximum input size" },
        { type: "Response Time", value: "~67s", notes: "Average" },
        { type: "Citations", value: "Yes", notes: "" },
      ],
      features: [
        "Advanced reasoning",
        "CoT Thinking",
        "Complex problem solving",
        "Deep analysis"
      ]
    },
    {
      name: "SparkAI Deep Research",
      description: "Most advanced model for in-depth research and analysis",
      icon: <i className="fas fa-brain text-blue-300"></i>,
      color: "text-blue-300",
      bgColor: "bg-blue-900/50",
      pricing: [
        { type: "Input", price: "0.92€", notes: "Per call" },
        { type: "Context Window", value: "128K tokens", notes: "Maximum input size" },
        { type: "Response Time", value: "~83s", notes: "Average" },
        { type: "Citations", value: "Yes", notes: "" },

      ],
      features: [
        "Deepest research capabilities",
        "Multi-source synthesis",
        "Advanced reasoning chains",
        "Highest accuracy level",
        "Beta access"
      ],
      beta: true
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-black"
    >
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative pt-24 pb-16">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-orange-900/20 to-black/80" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,rgba(249,115,22,0.15),transparent_50%)]" />
        </div>
        
        <div className="relative max-w-6xl mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
            API Pricing
          </h1>
          <p className="text-lg md:text-xl text-gray-300 max-w-2xl mx-auto">
            Simple, transparent pricing for our API. Pay only for what you use.
          </p>
        </div>
      </div>

      {/* Model Sections */}
      <div className="max-w-7xl mx-auto px-4 py-12 space-y-16">
        {models.map((model, index) => (
          <motion.section
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="relative"
          >
            {/* Model Header */}
            <div className="flex items-center gap-4 mb-8">
              <div className={`w-12 h-12 rounded-full ${model.bgColor} flex items-center justify-center`}>
                {model.icon}
              </div>
              <div>
                <h2 className={`text-2xl font-bold ${model.color} flex items-center gap-3`}>
                  {model.name}
                  {model.popular && (
                    <span className="px-3 py-1 bg-orange-500 text-white text-sm rounded-full">
                      Popular
                    </span>
                  )}
                </h2>
                <p className="text-gray-400">{model.description}</p>
              </div>
            </div>

            {/* Pricing Table */}
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="border-b border-gray-800">
                    <th className="py-4 px-6 text-left text-gray-400 font-medium">Type</th>
                    <th className="py-4 px-6 text-left text-gray-400 font-medium">Price/Value</th>
                    <th className="py-4 px-6 text-left text-gray-400 font-medium">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {model.pricing.map((item, i) => (
                    <tr key={i} className="border-b border-gray-800/50">
                      <td className="py-4 px-6 text-gray-300">{item.type}</td>
                      <td className="py-4 px-6">
                        <span className="text-white font-semibold">
                          {item.price || item.value}
                        </span>
                      </td>
                      <td className="py-4 px-6 text-gray-400">{item.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Features */}
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
              {model.features.map((feature, i) => (
                <div key={i} className="flex items-center gap-2 text-gray-300">
                  <i className="fas fa-check text-orange-500"></i>
                  {feature}
                </div>
              ))}
            </div>
          </motion.section>
        ))}
      </div>

      {/* FAQ Section */}
      <div className="mt-24 max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
          Frequently Asked Questions
        </h2>
        
        <div className="space-y-6">
          <div className="bg-gray-900/50 border border-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-semibold text-white mb-3">
              Why price is calculated by call?
            </h3>
            <p className="text-gray-400">
              We charge per API call to provide a simple and transparent pricing model. Each call represents a complete interaction with our AI models, making it easy to predict costs and scale based on your usage needs.
            </p>
          </div>

          <div className="bg-gray-900/50 border border-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-semibold text-white mb-3">
              Do you offer volume discounts?
            </h3>
            <p className="text-gray-400">
              Yes, we offer custom pricing for high-volume usage. Contact our sales team to discuss your specific needs and get a tailored quote.
            </p>
          </div>

          <div className="bg-gray-900/50 border border-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-semibold text-white mb-3">
              What payment methods do you accept?
            </h3>
            <p className="text-gray-400">
              We accept all major credit cards and offer invoice-based billing for enterprise customers. Payments are processed securely through Stripe.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default PlatformPricing; 