import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, IconButton } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      className="border-b border-gray-800"
      animate={{ backgroundColor: isOpen ? 'rgba(249, 115, 22, 0.05)' : 'transparent' }}
      transition={{ duration: 0.2 }}
    >
      <button
        className="w-full py-6 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <motion.span 
          className={`text-lg font-medium ${isOpen ? 'text-orange-500' : 'text-white'}`}
          animate={{ 
            textShadow: isOpen ? '0 0 8px rgba(249, 115, 22, 0.3)' : 'none'
          }}
          transition={{ duration: 0.2 }}
        >
          {question}
        </motion.span>
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
        >
          {isOpen ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </button>
      <motion.div 
        initial={{ height: 0, opacity: 0 }}
        animate={{ 
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0
        }}
        transition={{ duration: 0.2 }}
        className="overflow-hidden"
      >
        <div className="pb-6">
          <p className="text-gray-400">{answer}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "Why use Freiya for research?",
      answer: "Freiya ensures unbiased analysis by providing fact-based and referenced insights while avoiding hallucinations. It leverages advanced reasoning to break down complex scientific concepts, making it easier for users to navigate academic literature. Researchers can perform efficient literature reviews by instantly finding relevant papers, significantly reducing research time. Freiya is collaboration-ready, enabling research teams to share findings, annotate insights, and work together seamlessly."
    },
    {
      question: "How to get started with Freiya?",
      answer: "To begin using Freiya, users first need to sign up and create an account, which allows them to explore its capabilities. After logging in, they can ask research-related questions in natural language. The AI provides responses backed by citations from academic sources. Users can review these cited responses, save important findings, and share them with colleagues."
    },
    {
      question: "Where can I use Freiya?",
      answer: "Freiya is available via its web application, where users can directly interact with the AI. In the future, it also provide an API that integrates with custom research workflows, making it a powerful tool for institutional and enterprise use. University portals can incorporate Freiya to enhance academic research and learning experiences. The platform is mobile-friendly, ensuring accessibility from tablets and smartphones for research on the go."
    },
    {
      question: "When should I use Freiya?",
      answer: "Freiya is useful when conducting literature reviews, as it helps researchers find key references efficiently. It can generate research summaries and extract insights from academic papers, making it valuable for organizing knowledge. The AI is also effective in validating claims through citation tracking, ensuring that research conclusions are well-supported. It enhances collaborative efforts by allowing teams to share information in real time. Additionally, Freiya is beneficial when preparing for conferences, writing papers, or drafting grant applications."
    },
    {
      question: "Who can benefit from Freiya?",
      answer: "Researchers and scientists can use Freiya to accelerate their discovery process and analyze large volumes of data. Students and academics benefit from its ability to improve research quality and understanding. Journalists and analysts can verify claims and support their findings with citations. Enterprises and R&D teams can integrate Freiya into their workflows to extract valuable knowledge. Policymakers and NGOs can rely on AI-generated insights to make data-driven decisions in various domains."
    },
    {
      question: "How does Freiya improve research communications?",
      answer: "Freiya allows users to track their research progress saving the conversations the user has with the AI, ensuring that their history is accessible for future reference. It soon will provide bookmarking and highlighting features for storing important references and exporting AI-generated insights into custom reports for documentation and collaboration purposes."
    },
    {
      question: "What features are offered?",
      answer: "Freiya ensures that all AI responses are reference-backed, reinforcing trust in the generated insights. The platform supports multiple languages, making it accessible to an international audience. Research collaboration features enable teams and institutions to work together seamlessly. The NOVA version unlocks advanced reasoning capabilities, allowing users to gain deeper logical insights into their research topics."
    },
    {
      question: "How is Freiya different from ChatGPT and other AI tools?",
      answer: "Unlike general-purpose chatbots, Freiya is specifically optimized for scientific research and academic analysis. It prioritizes trust and transparency by sourcing all claims from credible academic papers, ensuring that users receive verifiable information. The system is built on specialized AI models designed for logical reasoning and advanced research applications. It also offers customizable AI interactions, allowing users to tailor their experience based on specific research needs."
    },
  ];

  return (
    <section className="py-20 px-4 md:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Left Column */}
          <div>
            <div className="sticky top-24">
                <h2
                    className="text-orange-500 mb-4 text-sm"
                >
                    Understanding how our platform works
                </h2>
                <h1
                    className="text-4xl font-bold text-white mb-6"
                >
                    Your Guide to Freiya's Advanced Research Copilot
                </h1>
                <p
                    className="text-gray-400"
                >
                    Freiya is an AI-powered research assistant designed to accelerate scientific discovery and analysis. It provides unbiased, cited, and referenced insights using an extensive database of over 80+ million open access academic papers, making research more accessible and efficient.
                </p>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-2 "
            id="faq"
          >
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection; 