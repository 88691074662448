import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../pages/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import ThreeTierPricing from "../components/PricingSection.jsx";

const PricingPage = ({ activeLink, setActiveLink }) => {
  const navigate = useNavigate();

  const handleFAQClick = () => {
    navigate('/#faq');
    // Add a small delay to ensure the navigation happens before scrolling
    setTimeout(() => {
      const faqElement = document.getElementById('faq');
      if (faqElement) {
        faqElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <div className="bg-black min-h-screen w-full">
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      
      {/* Hero Section */}
      <div className="relative pt-16 md:pt-24 pb-12 md:pb-16">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-900/20 to-black/80" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,rgba(0,0,255,0.15),transparent_50%)]" />
        </div>
        
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
            Simple, Transparent Pricing
          </h1>
          <p className="text-base sm:text-lg md:text-xl text-gray-300 max-w-2xl mx-auto">
            Choose the perfect plan for your needs. Get started with our powerful AI tools today.
          </p>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="relative z-10 max-w-7xl mx-auto px-2 sm:px-4 pb-2 mt-[-30px] sm:mt-[-50px]">
        <ThreeTierPricing />
      </div>

      {/* FAQ Preview Section */}
      <div className="relative bg-gray-900 py-12 sm:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-6 sm:mb-8">
            Frequently Asked Questions
          </h2>
          <p className="text-sm sm:text-base text-gray-300 mb-6 sm:mb-8">
            Have questions? We're here to help. Check out our FAQ section or contact our support team.
          </p>
          <button 
            onClick={handleFAQClick}
            className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
          >
            View FAQ
            <i className="fas fa-arrow-right ml-2" />
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PricingPage;
