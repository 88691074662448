import React from "react";
import { motion } from "framer-motion";

const models = [
  {
    name: "Freiya Fast",
    description: "Perfect for quick answers and basic research tasks",
    icon: <i className="fas fa-bolt text-gray-300"></i>,
    color: "from-gray-600 to-gray-700",
    features: [
      "Fast response times",
      "Basic research capabilities",
      "Cost-effective solution",
      "Ideal for simple queries"
    ],
    performance: "67.77%",
    responseTime: "14s",
    tier: "Personal Plan"
  },
  {
    name: "Freiya Focus",
    description: "Specialized for targeted article analysis and research",
    icon: <i className="fas fa-bullseye text-purple-300"></i>,
    color: "from-purple-600 to-purple-700",
    features: [
      "Optimized for article tasks",
      "Enhanced accuracy",
      "Rapid processing",
      "Detailed analysis"
    ],
    performance: "71.23%",
    responseTime: "6s",
    paperSpecialist: true,
    tier: "Personal Plan"
  },
  {
    name: "Freiya Pro",
    description: "Advanced capabilities for comprehensive research",
    icon: <i className="fas fa-star text-green-300"></i>,
    color: "from-green-600 to-green-700",
    features: [
      "Comprehensive research",
      "Advanced analysis",
      "Multiple source integration",
      "Enhanced accuracy"
    ],
    performance: "78.13%",
    responseTime: "25s",
    popular: true,
    tier: "Expert Plan"
  },
  {
    name: "Freiya Nova",
    description: "Superior reasoning and deep analytical capabilities",
    icon: <i className="fas fa-crown text-yellow-300"></i>,
    color: "from-yellow-600 to-yellow-700",
    features: [
      "Advanced reasoning",
      "CoT Thinking",
      "Complex problem solving",
      "Deep analysis",
    ],
    performance: "87.52%",
    responseTime: "47s",
    powerful: true,
    tier: "NOVA Plan"
  },
  {
    name: "Freiya Deep Research",
    description: "Beta version with cutting-edge research capabilities",
    icon: <i className="fas fa-microscope text-blue-300"></i>,
    color: "from-blue-600 to-blue-700",
    features: [
      "Cutting-edge research",
      "Highest accuracy",
      "In-depth analysis",
      "Beta features access"
    ],
    performance: "96.42%",
    responseTime: "83s",
    testing: true,
    highlight: true,
    tier: "Coming to NOVA Plan"
  }
];

const ModelCard = ({ model, index }) => {
  // Helper function to determine badge color based on tier
  const getTierColor = (tier) => {
    if (tier.includes("Personal")) return "bg-blue-500/20 text-blue-300";
    if (tier.includes("Expert")) return "bg-green-500/20 text-green-300";
    if (tier.includes("NOVA")) return "bg-amber-500/20 text-amber-300";
    if (tier.includes("Coming")) return "bg-orange-500/20 text-orange-300";
    return "bg-gray-500/20 text-gray-300"; // Default fallback
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className={`relative group rounded-xl overflow-hidden backdrop-blur-lg border border-gray-800 flex flex-col
        ${model.highlight ? 'bg-gradient-to-br from-orange-500/10 to-orange-600/10' : 'bg-gray-900/50'}`}
    >
      {/* Gradient overlay */}
      <div className={`absolute inset-0 bg-gradient-to-br ${model.color} opacity-0 
        transition-opacity duration-300 group-hover:opacity-10 z-0`} />
      
      {/* Content */}
      <div className="p-6 relative z-1 flex-grow">
        {/* Header */}
        <div className="flex items-center gap-3 mb-4">
          <span className="text-3xl">{model.icon}</span>
          <div>
            <h3 className="text-xl font-bold text-white flex items-center gap-2 flex-wrap">
              {model.name}
              {model.beta && (
                <span className="text-xs px-2 py-1 rounded-full bg-blue-500/20 text-blue-300">
                  BETA
                </span>
              )}
              {model.paperSpecialist && (
                <span className="text-xs px-2 py-1 rounded-full bg-orange-500/20 text-orange-300">
                  PAPER SPECIALIST
                </span>
              )}
              {model.popular && (
                <span className="text-xs px-2 py-1 rounded-full bg-green-500/20 text-green-300">
                  MOST POPULAR
                </span>
              )}
              {model.powerful && (
                <span className="text-xs px-2 py-1 rounded-full bg-yellow-500/20 text-yellow-300">
                  MOST POWERFUL
                </span>
              )}
              {model.testing && (
                <span className="text-xs px-2 py-1 rounded-full bg-blue-500/20 text-blue-300">
                  TESTING INTERNALLY
                </span>
              )}
            </h3>
            <p className="text-sm text-gray-400">{model.description}</p>
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="bg-black/30 rounded-lg p-3">
            <p className="text-sm text-gray-400 mb-1">Performance</p>
            <p className="text-xl font-bold text-white">{model.performance}</p>
          </div>
          <div className="bg-black/30 rounded-lg p-3">
            <p className="text-sm text-gray-400 mb-1">Mean Response Time</p>
            <p className="text-xl font-bold text-white">{model.responseTime}</p>
          </div>
        </div>

        {/* Features */}
        <ul className="space-y-2">
          {model.features.map((feature, idx) => (
            <li key={idx} className="flex items-center gap-2 text-gray-300">
              <i className="fas fa-check text-green-500" />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      
      {/* Tier badge - positioned at the bottom center */}
      <div className="pb-5 pt-2 text-center border-t border-gray-800 mt-auto">
        <span className={`text-xs px-3 py-1 rounded-full ${getTierColor(model.tier)}`}>
          {model.tier}
        </span>
      </div>
    </motion.div>
  );
};

const ModelTiersSection = () => {
  // Separate Focus model from general models
  const focusModel = models.find(m => m.name === "Freiya Focus");
  const generalModels = models.filter(m => m.name !== "Freiya Focus");

  return (
    <section className="py-16 bg-black">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            Choose Your Model
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Select from our range of specialized AI models, each designed for specific research needs
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-8">
          {/* General purpose models */}
          <div className="lg:col-span-3">
            <h3 className="text-xl font-semibold text-white mb-4">
              General Purpose Models
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
              {generalModels.map((model, index) => (
                <ModelCard key={index} model={model} index={index} />
              ))}
            </div>
          </div>

          {/* Focus model */}
          <div className="lg:col-span-1">
            <h3 className="text-xl font-semibold text-white mb-4">
              Specialized for Papers
            </h3>
            <div className="h-full">
              <ModelCard 
                model={{
                  ...focusModel,
                  description: "Optimized specifically for research paper analysis and comprehension",
                  features: [
                    "Paper-specific analysis",
                    "Rapid processing",
                    "Citation handling",
                    "Context awareness"
                  ]
                }} 
                index={0}
              />
            </div>
          </div>
        </div>

        {/* Info banner about Focus */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-purple-500/10 border border-purple-500/20 rounded-xl p-6 mt-8"
        >
          <div className="flex items-start gap-4">
            <div className="text-2xl">
              <i className="fas fa-bullseye text-purple-300"></i>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-white mb-2">
                About Freiya Focus
              </h4>
              <p className="text-gray-400">
                Unlike our general-purpose models, Freiya Focus is specifically designed for scientific paper analysis. 
                It excels at understanding academic content, managing citations, and providing focused insights from research papers. 
                Choose Focus when you need to deeply analyze specific academic documents.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ModelTiersSection; 