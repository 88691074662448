import React from "react";
import { Box, Flex, Heading, Text, Button, Stack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import FreiyaLanding from "../assets/sparkai-landing.png";
import { IoTimerOutline } from "react-icons/io5";
import { RiTimerFlashLine } from "react-icons/ri";


const MotionBox = motion(Box); // Framer Motion applied to Box
const MotionHeading = motion(Heading); // Framer Motion applied to Heading

const HeroSection = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.3 }}
      className="flex flex-col items-center justify-center w-full min-h-screen bg-black text-white px-4 pt-20 relative"
    >
      <div className="flex flex-col items-center justify-center max-w-[1200px] text-center gap-6 relative z-10">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
          className="text-5xl md:text-7xl lg:text-8xl font-bold tracking-normal"
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-600 animate-shimmer bg-[size:200%_100%]">
            Freiya
          </span>
          <span>, the </span>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-300 via-purple-300 to-blue-300 animate-soft-pulse bg-[size:200%_100%]">
            unbiased
          </span>
          <span> research copilot</span>
        </motion.h1>
        
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.7 }}
          className="text-gray-400 text-lg md:text-xl lg:text-2xl max-w-[800px]"
        >
          Where AI meets research excellence—smarter insights, faster results all with peer-reviewed citations. Stop wasting time on tedious research and try Freiya today!
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.9 }}
        >
          <motion.a 
            href="https://sparkai.ederspark.com"
            className="inline-flex items-center justify-center bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white px-10 py-3 rounded-full text-lg font-semibold mt-8 transition-all duration-300 shadow-lg hover:shadow-orange-500/50 relative overflow-hidden group"
            whileHover={{ 
              scale: 1.05,
              transition: { duration: 0.2 }
            }}
            whileTap={{ 
              scale: 0.95,
              transition: { duration: 0.1 }
            }}
            animate={{
              boxShadow: ["0 5px 15px rgba(251, 146, 60, 0.2)", "0 8px 25px rgba(251, 146, 60, 0.4)", "0 5px 15px rgba(251, 146, 60, 0.2)"],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <motion.span 
              className="absolute inset-0 bg-gradient-to-r from-orange-400/20 to-transparent"
              animate={{
                x: ["100%", "-100%"]
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "linear"
              }}
            />
            <span className="mr-3 relative z-10 group-hover:translate-x-1 transition-transform duration-200">
              Chat with Freiya now
            </span>
            <motion.svg 
              className="w-5 h-5 relative z-10"
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
              animate={{ 
                x: [0, 5, 0],
                scale: [1, 1.1, 1]
              }}
              transition={{ 
                repeat: Infinity, 
                duration: 1.5,
                ease: "easeInOut"
              }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </motion.svg>
          </motion.a>
        </motion.div>
      </div>
      
      {/* Glowing background effect positioned at bottom */}
      <div className="absolute bottom-[-50%] left-[50%] transform -translate-x-1/2 w-[800px] h-[800px] opacity-20 bg-blue-500 rounded-full blur-[128px]" />
    </motion.div>
  );
};

export default HeroSection;
