import React, { useState } from "react";
import { motion } from "framer-motion";

const PricingCard = ({ title, price, description, features, buttonText, isPopular, color, buttonLink }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`relative bg-white/10 backdrop-blur-lg rounded-3xl p-8 mt-6 ${
      isPopular 
        ? 'border-2 border-orange-500 bg-gradient-to-b from-orange-500/10 to-transparent' 
        : 'border border-white/20'
    }`}
  >
    {isPopular && (
      <div className="absolute -top-5 left-1/2 -translate-x-1/2 w-max">
        <span className="bg-orange-500 text-white px-4 sm:px-6 py-1.5 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap">
          Most Popular
        </span>
      </div>
    )}
    <h3 className={`text-2xl font-bold mb-2 ${
      isPopular ? 'text-orange-500' : 'text-white'
    }`}>{title}</h3>
    <div className="flex items-baseline mb-4 flex-wrap">
      <span className="text-3xl sm:text-4xl font-bold text-white">{price}€</span>
      <span className="text-sm sm:text-base text-gray-300 ml-2">/month</span>
    </div>
    <p className="text-sm sm:text-base text-gray-300 mb-6">{description}</p>
    <ul className="space-y-4 mb-8">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-sm sm:text-base text-gray-200">
          <span className={`mr-3 text-${color}`}>✓</span>
          {feature}
        </li>
      ))}
    </ul>
    <button
      className={`w-full py-3 rounded-full font-semibold transition-all text-sm sm:text-base
        ${isPopular 
          ? 'bg-orange-500 hover:bg-orange-600 text-white' 
          : 'bg-white/20 hover:bg-white/30 text-white'}`}
      onClick={() => window.open(buttonLink, '_blank')}
    >
      {buttonText}
    </button>
  </motion.div>
);

const PricingSection = () => {
  const [isYearly, setIsYearly] = useState(false);

  const plans = [
    {
      title: "Personal Plan",
      price: isYearly ? (24.95 * 0.8).toFixed(2) : "24.95",
      description: "Perfect for individual researchers",
      features: [
        "Unlimited AI conversations",
        "Unlimited Freiya Mini messages",
        "100 Pro messages per month",
        "Focus mode for paper analysis",
        "Active support"
      ],
      buttonText: "Choose Personal",
      buttonLink: "https://sparkai.ederspark.com",
      color: "emerald-500",
    },
    {
      title: "Expert Plan",
      price: isYearly ? (64.95 * 0.8).toFixed(2) : "64.95",
      description: "For power users and professionals",
      features: [
        "Everything in Personal plan",
        "Unlimited Pro messages per month",
        "50 NOVA messages per month",
        "Advanced research capabilities",
        "24/7 support"
      ],
      buttonText: "Choose Expert",
      buttonLink: "https://sparkai.ederspark.com",
      isPopular: true,
      color: "orange-500",
    },
    {
      title: "NOVA Plan",
      price: isYearly ? (99.95 * 0.8).toFixed(2) : "99.95",
      description: "For enterprises and power users",
      features: [
        "Everything in Expert plan",
        "Unlimited NOVA messages",
        "Highest priority in queue",
        "Suggest new features",
        "Priority access to new features"
      ],
      buttonText: "Choose NOVA",
      buttonLink: "https://sparkai.ederspark.com",
      color: "purple-500",
    }
  ];

  return (
    <section className="py-20 px-4 relative">
      <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-900/50 to-black" />
      
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="flex justify-center mb-12">
          <div className="flex items-center gap-2 sm:gap-4 bg-white/5 backdrop-blur-sm rounded-full p-1.5">
            <span 
              className={`px-3 sm:px-6 py-2 rounded-full cursor-pointer transition-all text-sm sm:text-base ${
                !isYearly ? 'bg-white/10 text-white' : 'text-gray-400 hover:text-gray-300'
              }`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </span>
            <button
              className="w-12 h-6 rounded-full bg-gray-700 relative"
              onClick={() => setIsYearly(!isYearly)}
            >
              <div className={`w-4 h-4 bg-white rounded-full absolute top-1 transition-all ${
                isYearly ? 'left-7' : 'left-1'
              }`} />
            </button>
            <span 
              className={`px-3 sm:px-6 py-2 rounded-full cursor-pointer flex items-center gap-1 sm:gap-2 transition-all text-sm sm:text-base ${
                isYearly ? 'bg-white/10 text-white' : 'text-gray-400 hover:text-gray-300'
              }`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
              <span className="text-xs sm:text-sm text-emerald-400 font-medium">Save 20%</span>
            </span>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-lg md:max-w-none mx-auto">
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
