import React from "react";
import Navbar from "../pages/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import PrivacyPolicy from "../components/PrivacyPolicy.jsx";
import { motion } from "framer-motion";

const PrivacyPolicyPage = ({ activeLink, setActiveLink }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-black pt-24 overflow-x-hidden"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      <div className="max-w-6xl mx-auto">
        <PrivacyPolicy />
      </div>
      <Footer />
    </motion.div>
  );
};

export default PrivacyPolicyPage;
