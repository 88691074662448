import React, { useState } from "react";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Rectangle, ReferenceArea } from "recharts";

const data = [
  { name: "FREIYA FAST", time: 14, performance: 67.77, color: "#808080", radius: 4 },
  { name: "FREIYA FOCUS (ONLY ARTICLE TASKS)", time: 6, performance: 71.23, color: "#9932CC", radius: 4 },
  { name: "FREIYA PRO", time: 25, performance: 78.13, color: "#32CD32", radius: 4 },
  { name: "FREIYA NOVA", time: 47, performance: 87.52, color: "#FFD700", radius: 4 },
  { name: "FREIYA DEEP RESEARCH (BETA)", time: 83, performance: 96.42, color: "#1E90FF", radius: 4 }
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/10 backdrop-blur-md p-4 rounded-lg border border-gray-700">
        <p className="font-bold" style={{ color: payload[0].payload.color }}>
          {payload[0].payload.name}
        </p>
        <p className="text-gray-300">
          Time: {payload[0].payload.time}s
        </p>
        <p className="text-gray-300">
          Performance: {payload[0].payload.performance}%
        </p>
      </div>
    );
  }
  return null;
};

const ModelComparisonSection = () => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [hoveredPoint, setHoveredPoint] = useState(null);

  // Add a hook to track container width
  const [containerWidth, setContainerWidth] = React.useState(0);
  const chartContainerRef = React.useRef(null);

  React.useEffect(() => {
    const updateWidth = () => {
      if (chartContainerRef.current) {
        setContainerWidth(chartContainerRef.current.offsetWidth);
      }
    };

    // Initial width
    updateWidth();

    // Add resize listener
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  // Calculate responsive dimensions
  const aspectRatio = 0.6; // height = width * aspectRatio
  const chartWidth = Math.min(1100, containerWidth - 20); // max width 1100px, padding 20px
  const chartHeight = Math.max(400, chartWidth * aspectRatio); // min height 400px

  // Define tier areas with proper coordinate ranges that extend all the way down
  const tiers = [
    { 
      name: "Personal Plan", 
      x1: 0, 
      x2: 23, 
      y1: 0, // Changed from 65 to 0 to extend to the bottom
      y2: 100, 
      fill: "rgba(128, 128, 128, 0.2)", 
      stroke: "rgba(128, 128, 128, 0.5)",
      models: ["FREIYA FAST", "FREIYA FOCUS (ONLY ARTICLE TASKS)"]
    },
    { 
      name: "Expert Plan", 
      x1: 23, 
      x2: 45, 
      y1: 0, // Changed from 65 to 0 to extend to the bottom
      y2: 100, 
      fill: "rgba(50, 205, 50, 0.2)", 
      stroke: "rgba(50, 205, 50, 0.5)",
      models: ["FREIYA PRO"]
    },
    { 
      name: "Nova Plan", 
      x1: 45, 
      x2: 100, 
      y1: 0, // Changed from 65 to 0 to extend to the bottom
      y2: 100, 
      fill: "rgba(255, 215, 0, 0.2)", 
      stroke: "rgba(255, 215, 0, 0.5)",
      models: ["FREIYA NOVA", "FREIYA DEEP RESEARCH (BETA)"]
    }
  ];

  // Custom scatter point with label
  const CustomScatterPoint = (props) => {
    const { cx, cy, fill, payload } = props;
    const isSelected = selectedPoint === payload.name;
    const isHovered = hoveredPoint === payload.name;
    const radius = isSelected ? 8 : isHovered ? 6 : payload.radius;
    
    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill={fill}
          style={{
            transition: 'all 0.3s ease',
            cursor: 'pointer',
            filter: isSelected || isHovered ? 'brightness(1.2) drop-shadow(0 0 5px rgba(255,255,255,0.5))' : 'none',
          }}
          onClick={() => setSelectedPoint(isSelected ? null : payload.name)}
          onMouseEnter={() => setHoveredPoint(payload.name)}
          onMouseLeave={() => setHoveredPoint(null)}
        />
        {containerWidth >= 768 && ( // Only show text on screens >= 768px
          <text
            x={cx + radius + 5}
            y={cy}
            dy=".35em"
            fill="#fff"
            fontSize="10px"
            style={{
              transition: 'all 0.3s ease',
              opacity: isSelected || isHovered ? 1 : 0.7,
              filter: isSelected || isHovered ? 'brightness(1.2)' : 'none',
            }}
          >
            {payload.name}
          </text>
        )}
      </g>
    );
  };

  return (
    <section className="py-8 md:py-16 px-2 md:px-4 bg-black">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-4">
            Model Performance Comparison
          </h2>
          <p className="text-sm md:text-base text-gray-400 max-w-2xl mx-auto px-2">
            See how our models stack up against industry standards in our research performance benchmarks
          </p>
        </div>
        <div className="flex justify-center" ref={chartContainerRef}>
          <div className="relative w-full max-w-[1200px] rounded-lg overflow-hidden p-2 md:p-4 bg-black/50 backdrop-blur-sm">
            <ScatterChart
              width={chartWidth}
              height={chartHeight}
              margin={{
                top: containerWidth < 768 ? 20 : 20,
                right: containerWidth < 768 ? 30 : 100,
                bottom: 50,
                left: containerWidth < 768 ? 30 : 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#333" />
              
              {/* Add tier backgrounds using ReferenceArea */}
              {tiers.map((tier, index) => (
                <ReferenceArea
                  key={`tier-${index}`}
                  x1={tier.x1}
                  x2={tier.x2}
                  y1={tier.y1}
                  y2={tier.y2}
                  fill={tier.fill}
                  stroke={tier.stroke}
                  strokeOpacity={0.8}
                  label={{
                    value: tier.name,
                    position: 'insideBottomRight',
                    fill: '#fff',
                    fontSize: 10,
                    opacity: 0.7
                  }}
                />
              ))}
              
              <XAxis
                type="number"
                dataKey="time"
                name="Time"
                domain={[0, 100]}
                tick={{ fill: '#fff', fontSize: containerWidth < 768 ? 10 : 12 }}
              >
                <Label 
                  value="TIME SPENT ON TASK" 
                  position="bottom" 
                  fill="#fff" 
                  offset={20}
                  style={{ fontSize: containerWidth < 768 ? 10 : 12 }}
                />
              </XAxis>
              <YAxis
                type="number"
                dataKey="performance"
                name="Performance"
                domain={[0, 100]}
                tick={{ fill: '#fff', fontSize: containerWidth < 768 ? 10 : 12 }}
              >
                <Label 
                  value="PERFORMANCE %" 
                  angle={-90} 
                  position="left" 
                  fill="#fff" 
                  offset={containerWidth < 768 ? 10 : 20}
                  style={{ fontSize: containerWidth < 768 ? 10 : 12 }}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                verticalAlign="top"
                height={containerWidth < 768 ? 60 : 36}
                layout="horizontal"
                formatter={(value) => (
                  <span style={{
                    color: '#fff',
                    fontSize: containerWidth < 768 ? '10px' : '12px',
                    lineHeight: '1.2',
                    width: containerWidth < 768 ? '50%' : 'auto',
                  }}>
                    {containerWidth < 480 ? value.replace('SPARKAI ', '').split(' (')[0] : value}
                  </span>
                )}
                wrapperStyle={{
                  paddingTop: '0px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: containerWidth < 768 ? 'wrap' : 'nowrap',
                  gap: '8px',
                  maxWidth: containerWidth < 768 ? '300px' : '100%',
                  margin: '0 auto'
                }}
              />
              {data.map((entry) => (
                <Scatter
                  key={entry.name}
                  name={entry.name}
                  data={[entry]}
                  fill={entry.color}
                  line={false}
                  shape={<CustomScatterPoint />}
                  legendType="circle"
                />
              ))}
            </ScatterChart>
            
            {/* Add selected point details */}
            {selectedPoint && (
              <div className="absolute bottom-4 left-4 bg-white/10 backdrop-blur-md p-4 rounded-lg border border-gray-700 transition-all duration-300">
                <h3 className="font-bold text-white mb-2">
                  {data.find(d => d.name === selectedPoint)?.name}
                </h3>
                <p className="text-gray-300">
                  Click anywhere on the chart to deselect
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModelComparisonSection; 