import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  FiHome, FiCpu, FiDollarSign, FiKey, FiActivity, 
  FiBarChart2, FiUsers, FiSettings, FiHelpCircle 
} from 'react-icons/fi';

const PlatformSidebar = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    {
      title: 'DASHBOARD',
      items: [
        { icon: <FiHome size={20} />, label: 'Overview', path: '/platform' },
        //{ icon: <FiActivity size={20} />, label: 'Activity', path: '/platform/activity' },
        //{ icon: <FiBarChart2 size={20} />, label: 'Analytics', path: '/platform/analytics' },
      ]
    },
    {
      title: 'API MANAGEMENT',
      items: [
        { icon: <FiKey size={20} />, label: 'API Keys', path: '/platform/api-keys' },
        { icon: <FiCpu size={20} />, label: 'Usage', path: '/platform/usage' },
        { icon: <FiDollarSign size={20} />, label: 'Billing', path: '/platform/billing' },
      ]
    },
    {
      title: 'ACCOUNT',
      items: [
        { icon: <FiUsers size={20} />, label: 'Team', path: '/platform/team' },
        { icon: <FiSettings size={20} />, label: 'Settings', path: '/platform/settings' },
        { icon: <FiHelpCircle size={20} />, label: 'Support', path: '/platform/support' },
      ]
    }
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <motion.div
      initial={{ x: -280 }}
      animate={{ x: 0 }}
      className="fixed left-0 top-16 h-[calc(100vh-64px)] w-64 bg-[#0B0F19] border-r border-gray-800/50 z-40 transition-all duration-300"
    >
      <div className="flex flex-col h-full py-6">
        {menuItems.map((section, index) => (
          <div key={index} className="mb-8 px-4">
            <h3 className="text-gray-500 text-xs font-medium mb-4 pl-3">
              {section.title}
            </h3>
            <div className="space-y-1">
              {section.items.map((item, itemIndex) => (
                <motion.button
                  key={itemIndex}
                  whileHover={{ x: 4 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => navigate(item.path)}
                  className={`w-full flex items-center gap-3 px-3 py-2 rounded-lg transition-all duration-200 ${
                    isActive(item.path)
                      ? 'bg-orange-500/10 text-orange-400'
                      : 'text-gray-400 hover:bg-gray-800/30 hover:text-white'
                  }`}
                >
                  <span className={`${isActive(item.path) ? 'text-orange-400' : 'text-gray-400'}`}>
                    {item.icon}
                  </span>
                  <span className="text-sm">{item.label}</span>
                  {isActive(item.path) && (
                    <motion.div
                      layoutId="activeIndicator"
                      className="ml-auto w-1.5 h-1.5 rounded-full bg-orange-400"
                    />
                  )}
                </motion.button>
              ))}
            </div>
          </div>
        ))}

        {/* Usage Limits Card */}
        <div className="mt-auto px-4">
          <div className="bg-[#151923] rounded-xl p-4">
            <h4 className="text-gray-300 text-sm font-medium mb-4">Usage Limits</h4>
            <div className="space-y-4">
              <div>
                <div className="flex justify-between text-xs mb-2">
                  <span className="text-gray-400">API Calls</span>
                  <span className="text-gray-300">2,220 / 10,000</span>
                </div>
                <div className="h-1 bg-gray-800/50 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-orange-500 rounded-full" 
                    style={{ width: '22.2%' }} 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PlatformSidebar; 