import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { RiLockLine } from 'react-icons/ri';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Slight delay to prevent flash on initial load
    const timer = setTimeout(() => {
      const consent = localStorage.getItem('cookieConsent');
      if (!consent) {
        setIsVisible(true);
      }
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ type: "spring", stiffness: 100, damping: 20 }}
          className="fixed bottom-0 left-0 right-0 z-50"
        >
          <div className="bg-gradient-to-b from-gray-900/95 to-black/95 backdrop-blur-md border-t border-gray-800/50 shadow-2xl">
            <div className="max-w-7xl mx-auto p-4 md:p-6">
              <div className="flex flex-col md:flex-row items-center justify-between gap-6">
                <div className="flex items-start gap-4 flex-1">
                  <div className="hidden md:flex h-12 w-12 rounded-full bg-orange-500/10 items-center justify-center flex-shrink-0">
                    <IoShieldCheckmarkOutline className="h-6 w-6 text-orange-500" />
                  </div>
                  <div className="space-y-2">
                    <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                      <RiLockLine className="h-5 w-5 text-orange-500" />
                      Your Privacy Matters to Us
                    </h3>
                    <p className="text-sm md:text-base text-gray-300 leading-relaxed max-w-3xl">
                      We use cookies and similar technologies to enhance your experience, analyze traffic, and personalize content. 
                      We value your privacy and ensure your data is handled with the utmost care and security. 
                      By continuing to use our site, you consent to our{' '}
                      <Link to="/cookie-policy" className="text-orange-500 hover:text-orange-400 underline decoration-orange-500/30 hover:decoration-orange-400/50 transition-colors">
                        cookie policy
                      </Link>.
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <Link
                    to="/privacy-policy"
                    className="text-sm text-gray-400 hover:text-white transition-colors whitespace-nowrap"
                  >
                    Privacy Policy
                  </Link>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={acceptCookies}
                    className="whitespace-nowrap px-6 py-3 bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white rounded-full transition-all duration-200 font-medium shadow-lg shadow-orange-500/25 hover:shadow-orange-500/40"
                  >
                    Accept & Continue
                  </motion.button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieBanner; 