import React, { useState, useEffect } from 'react'
import './Ederpad.css'

function SmartBlock({ variables, addVariable, removeVariable, checkVariable, defaultTitle }) {
    const [title, setTitle] = useState(defaultTitle || "")
    const [expression, setExpression] = useState("")
    const [type, setType] = useState("number")
    const [calculatedValue, setCalculatedValue] = useState("")

    useEffect(() => {
        if (defaultTitle) {
            addVariable(defaultTitle, calculatedValue || "")
        }
    }, [])

    useEffect(() => {
        if (expression && expression.includes('{')) {
            const result = evaluateExpression(expression)
            if (result !== null) {
                setCalculatedValue(result)
                addVariable(title, result)
            }
        }
    }, [variables, expression, title])

    const handleTitleOnChange = (e) => {
        const oldTitle = title
        const newTitle = e.target.value
        setTitle(newTitle)
        
        removeVariable(oldTitle)
        addVariable(newTitle, calculatedValue || "")
    }

    const evaluateExpression = (expr) => {
        // Replace all {varName} with their actual values
        let evaluatedExpr = expr
        const varPattern = /\{([^}]+)\}/g
        let match

        while ((match = varPattern.exec(expr)) !== null) {
            const varName = match[1]
            const varValue = checkVariable(varName)
            if (varValue === null) return null
            evaluatedExpr = evaluatedExpr.replace(`{${varName}}`, varValue)
        }

        try {
            // Only evaluate if the expression contains variables and valid operators
            if (expr.includes('{')) {
                // Validate that the expression only contains numbers, spaces, and allowed operators
                if (!/^[\d\s\{\}\+\-\*\/\.]+$/.test(evaluatedExpr)) {
                    console.error("Invalid characters in expression")
                    return null
                }
                // Evaluate the expression
                return eval(evaluatedExpr)
            }
            return expr
        } catch (error) {
            console.error("Invalid expression:", error)
            return null
        }
    }

    const handleValueOnChange = (e) => {
        const newValue = e.target.value
        setExpression(newValue)

        const result = evaluateExpression(newValue)
        console.log("Result", result)
        if (result !== null) {
            setCalculatedValue(result)
            addVariable(title, result)
        }

        // Check if it's a pure variable reference
        if (newValue.startsWith("{") && newValue.endsWith("}") && !newValue.includes("+")) {
            setType("variable")
        } else if (newValue.includes("{")) {
            setType("expression")
        } else {
            setType("number")
        }
    }

    return (
        <div className={`smart-block`}>
            <input
                className="ederpad-input"
                type="text" placeholder="Title"
                value={title}
                onChange={handleTitleOnChange}
            />
            <input
                className="ederpad-input"
                type="text" placeholder="Enter a value..."
                value={expression}
                onChange={handleValueOnChange}
            />
            <span>Type: {type}</span>
            <span>Value: {calculatedValue}</span>
        </div>
    )
}

function TextBlock() {
    return (
        <div className={`text-block`}>
            <input
                className="ederpad-input"
                type="text" placeholder="Write..."
            />
        </div>
    )
}

export default function Ederpad() {

    const [blocks, setBlocks] = useState([])
    const [variables, setVariables] = useState({})

    function addBlock(block) {
        setBlocks([...blocks, block])
    }

    function addVariable(title, value) {
        setVariables({ ...variables, [title]: value })
    }

    function checkVariable(variable) {
        if (variables[variable]) {
            console.log("Variable found", variables[variable])
            return variables[variable]
        }
        console.log("Variable not found", variable)
        return null
    }

    function removeVariable(index) {
        setVariables(Object.fromEntries(Object.entries(variables).filter(([key]) => key !== index)))
    }

    function addTextBlock() {
        addBlock(<TextBlock />)
    }
    
    function addSmartBlock() {
        // Find the highest existing var number
        const existingVarNumbers = Object.keys(variables)
            .filter(key => key.startsWith('var'))
            .map(key => parseInt(key.replace('var', '')))
        
        const nextNumber = existingVarNumbers.length > 0 
            ? Math.max(...existingVarNumbers) + 1 
            : 1

        addBlock(<SmartBlock 
            variables={variables}
            addVariable={addVariable} 
            removeVariable={removeVariable}
            checkVariable={checkVariable}
            defaultTitle={`var${nextNumber}`} 
        />)
    }

    function removeBlock(index) {
        setBlocks(blocks.filter((_, i) => i !== index))
        if (blocks[index].type === "variable") {
            removeVariable(blocks[index].title)
        }
    }

    return (
        <div className="w-full h-full flex flex-col items-center justify-start">
            <div className="w-6/12 h-full mt-40">
                {blocks.map((block, index) => (
                    <div key={index}
                        className="w-full h-full flex flex-row items-center justify-start"
                    >
                        {block}
                        <button 
                            className="button"
                            onClick={() => removeBlock(index)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <div
                    className="w-full h-full flex flex-row items-center justify-start"
                >
                    <button 
                        className="button"
                        onClick={addTextBlock}
                    >
                        Add Text Block
                    </button>
                    <button 
                        className="button"
                        onClick={addSmartBlock}
                    >
                        Add Smart Block
                    </button>
                </div>
            </div>
            <div className="w-6/12 h-full mt-40">
                <h1>Variables</h1>
                {Object.entries(variables).map(([title, value]) => (
                    <div key={title}>{title}: {value}</div>
                ))}
            </div>
        </div>
    )
}
