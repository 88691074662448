import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "../components/Footer.jsx";
import { motion } from "framer-motion";
import { FaIndustry, FaRobot, FaChartLine, FaUsers, FaGlobe, FaHandshake } from "react-icons/fa";
import { RiTimerFlashLine } from "react-icons/ri";
import { IoMdAnalytics } from "react-icons/io";
import { BsShieldCheck } from "react-icons/bs";
import DemoRequestModal from "../components/DemoRequestModal";

const EnterprisesPage = ({ activeLink, setActiveLink }) => {
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

  return (
    <div className="bg-gray-900 w-full">
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="pt-24 px-4 md:px-12 lg:px-24 pb-12 relative"
      >
        {/* Glowing background effect */}
        <div className="absolute bottom-0 left-[50%] transform -translate-x-1/2 w-[800px] h-[800px] opacity-20 bg-orange-500 rounded-full blur-[128px]" />

        <div className="max-w-7xl mx-auto relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h1 className="text-5xl md:text-7xl lg:text-8xl font-bold tracking-normal mb-6">
              <span className="text-white"> Drive </span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-600 animate-shimmer bg-[size:200%_100%]">
                Innovation
              </span>
              <span className="text-white"> with Enterprise </span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-600 animate-shimmer bg-[size:200%_100%]">
                AI
              </span>
            </h1>
            <p className="text-xl text-gray-400 max-w-3xl mx-auto">
              Empower your R&D teams with EderSpark's advanced AI tools to accelerate innovation and maintain competitive advantage
            </p>
          </motion.div>

          {/* Benefits Grid */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
          >
            {[
              {
                icon: <FaIndustry className="w-8 h-8" />,
                title: "Industry-Specific Insights",
                description: "Access tailored research insights relevant to your industry vertical"
              },
              {
                icon: <FaRobot className="w-8 h-8" />,
                title: "AI-Driven R&D",
                description: "Accelerate research and development with advanced AI analysis tools"
              },
              {
                icon: <BsShieldCheck className="w-8 h-8" />,
                title: "Enterprise Security",
                description: "Benefit from enterprise-grade security and data protection measures"
              },
              {
                icon: <RiTimerFlashLine className="w-8 h-8" />,
                title: "Rapid Innovation",
                description: "Cut research time by up to 60% with automated analysis and insights"
              },
              {
                icon: <FaHandshake className="w-8 h-8" />,
                title: "Dedicated Support",
                description: "Get priority access to our customer success and technical support teams"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 * index }}
                className="bg-gray-800 p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="text-orange-500 mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-bold text-gray-100 mb-2">{benefit.title}</h3>
                <p className="text-gray-300">{benefit.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Enterprise Features Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="bg-gray-800/50 backdrop-blur-lg border border-gray-700 p-12 rounded-2xl mb-16"
          >
            <h2 className="text-3xl font-bold text-white mb-8 text-center">Enterprise Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                "Volume-based Licensing",
                "Custom Training Sessions",
                "Priority Support",
                "Collaboration opportunities"
              ].map((feature, index) => (
                <div key={index} className="flex items-center gap-3">
                  <div className="text-orange-500">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-gray-300">{feature}</span>
                </div>
              ))}
            </div>
          </motion.div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center bg-gray-800/50 backdrop-blur-lg border border-gray-700 p-12 rounded-2xl mb-16"
          >
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Transform Your Enterprise Research?
            </h2>
            <p className="text-xl text-gray-300 mb-8">
              Join industry leaders in leveraging AI-powered research for competitive advantage
            </p>
            <motion.button
              onClick={() => setIsDemoModalOpen(true)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center justify-center bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white px-10 py-3 rounded-full text-lg font-semibold transition-all duration-300 shadow-lg hover:shadow-orange-500/50 relative overflow-hidden group"
            >
              <motion.span 
                className="absolute inset-0 bg-gradient-to-r from-orange-400/20 to-transparent"
                animate={{
                  x: ["100%", "-100%"]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
              <span className="mr-3 relative z-10 group-hover:translate-x-1 transition-transform duration-200">
                Schedule a Demo
              </span>
              <motion.svg 
                className="w-5 h-5 relative z-10"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
                animate={{ 
                  x: [0, 5, 0],
                  scale: [1, 1.1, 1]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 1.5,
                  ease: "easeInOut"
                }}
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </motion.svg>
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
      
      <DemoRequestModal 
        isOpen={isDemoModalOpen}
        onClose={() => setIsDemoModalOpen(false)}
      />
    </div>
  );
};

export default EnterprisesPage; 