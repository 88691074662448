import React from "react";
import { motion } from "framer-motion";

const problems = [
  {
    icon: <i className="fas fa-book-open"></i>,
    title: "Information Overload",
    description: "Scientific knowledge is growing at an unprecedented rate, making it impossible to keep up with all relevant publications."
  },
  {
    icon: <i className="fas fa-puzzle-piece"></i>,
    title: "Fragmented Sources",
    description: "Research is scattered across multiple platforms, journals, and databases, making comprehensive analysis difficult."
  },
  {
    icon: <i className="fas fa-hourglass-half"></i>,
    title: "Time Constraints",
    description: "Researchers waste hours navigating through fragmented sources instead of focusing on their core work."
  },
  {
    icon: <i className="fas fa-cogs"></i>,
    title: "Delayed Innovation",
    description: "Critical breakthroughs are slowed down, affecting global progress in science, technology, and medicine."
  }
];

const ProblemsSection = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-black to-gray-900">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-[4.5rem] md:leading-[6rem] font-bold text-white mb-6">
            Tired of spending <span className="text-orange-400 font-semibold">countless hours</span> searching for the right papers?
          </h2>
          <p className="text-gray-300 max-w-3xl mx-auto text-lg">
            Scientific literature is expanding by <span className="text-orange-400 font-semibold">5+ million papers annually</span>, creating unprecedented challenges for researchers navigating this growing knowledge landscape.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          {problems.map((problem, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
              className="bg-gradient-to-br from-gray-800/60 to-gray-900/60 backdrop-blur-sm border border-gray-700 rounded-xl p-7 relative overflow-hidden hover:shadow-lg transition-all duration-300"
            >
              <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-orange-500 to-orange-600/50"></div>
              <div className="relative z-10">
                <div className="text-3xl text-orange-500 mb-4">{problem.icon}</div>
                <h3 className="text-xl font-bold text-white mb-3">{problem.title}</h3>
                <p className="text-gray-300 leading-relaxed">{problem.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-gradient-to-br from-orange-900/30 to-red-900/20 border border-orange-800/30 rounded-xl p-8 shadow-lg"
        >
          <div className="flex flex-col md:flex-row items-start gap-8">
            <div className="md:w-2/3">
              <div className="flex items-center gap-3 mb-5">
                <div className="text-4xl text-orange-400">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h3 className="text-2xl font-bold text-white">
                  Strategic Implications
                </h3>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-5">
                <div className="flex items-start gap-3">
                  <div className="rounded-full bg-orange-500/20 p-2 mt-1">
                    <i className="fas fa-clock text-orange-400"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium mb-1">Research Efficiency</h4>
                    <p className="text-gray-300 text-sm">Researchers spend up to 45% of their time on literature searches rather than innovation</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <div className="rounded-full bg-orange-500/20 p-2 mt-1">
                    <i className="fas fa-lightbulb text-orange-400"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium mb-1">Missed Opportunities</h4>
                    <p className="text-gray-300 text-sm">Critical insights remain undiscovered within the vast landscape of unprocessed information</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <div className="rounded-full bg-orange-500/20 p-2 mt-1">
                    <i className="fas fa-university text-orange-400"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium mb-1">Institutional Impact</h4>
                    <p className="text-gray-300 text-sm">Organizations struggle to maintain competitive research advantages in rapidly evolving fields</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <div className="rounded-full bg-orange-500/20 p-2 mt-1">
                    <i className="fas fa-globe text-orange-400"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium mb-1">Global Innovation</h4>
                    <p className="text-gray-300 text-sm">Societal advancement slows as breakthrough connections remain hidden across disciplinary boundaries</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="md:w-1/3 md:border-l border-orange-800/50 md:pl-8 flex flex-col justify-center">
              <h4 className="text-lg font-medium text-white mb-4">Ready to transform your research workflow?</h4>
              <motion.a 
                href="https://sparkai.ederspark.com"
                className="inline-flex items-center justify-center w-full bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white px-6 py-3 rounded-lg font-medium transition-all duration-300 shadow-lg"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <span>Experience Freiya</span>
                <i className="fas fa-arrow-right ml-2"></i>
              </motion.a>
              <p className="text-gray-400 text-sm mt-3 text-center">Join leading researchers and institutions</p>
            </div>
          </div>
        </motion.div>

        <div className="mt-14 max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.7 }}
            className="text-center"
          >
            <p className="text-gray-400 mb-3">The scientific community requires a new paradigm</p>
            <h3 className="text-2xl text-white font-medium">
              Introducing an approach that makes accessing, synthesizing, and applying research <span className="text-orange-400">exponentially more effective</span>.
            </h3>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ProblemsSection; 