import React from "react";
import { motion } from "framer-motion";
import Navbar from "../pages/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import FoundersPicture from "../assets/founders-pic.jpg";

const AboutPage = ({ activeLink, setActiveLink }) => {
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-black w-full"
        >
            <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
            <motion.div 
                className="flex flex-col items-center justify-center gap-16 py-32 px-8 md:px-24"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                {/* Hero Section */}
                <motion.div 
                    className="text-center max-w-4xl mx-auto mb-16"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
                        Revolutionizing Scientific Discovery
                    </h1>
                    <p className="text-xl text-gray-400 leading-relaxed">
                        We're on a mission to transform how the world accesses and understands scientific knowledge
                    </p>
                </motion.div>

                {/* Founders Section */}
                <motion.div className="grid md:grid-cols-2 gap-16 items-center">
                    <motion.div 
                        className="w-full"
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                    >
                        <motion.img 
                            whileHover={{ scale: 1.05 }}
                            transition={{ duration: 0.3 }}
                            src={FoundersPicture} 
                            alt="Álvaro and Jose, founders of EderSpark" 
                            className="rounded-2xl shadow-2xl w-full object-cover border border-gray-800"
                        />
                    </motion.div>
                    <motion.div 
                        className="flex flex-col gap-8"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                    >
                        <h2 className="text-4xl font-bold text-white">Meet the Founders</h2>
                        <p className="text-xl text-gray-400 leading-relaxed">
                            <strong className="text-white">Álvaro and Jose</strong> combined their expertise in AI and scientific research to create <strong className="text-white">EderSpark</strong>. Their vision: to build the most powerful tool for scientific discovery in the digital age.
                        </p>
                    </motion.div>
                </motion.div>

                {/* Mission Section */}
                <motion.div 
                    className="bg-gray-900 rounded-3xl p-12 mt-16 w-full"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                >
                    <h2 className="text-4xl font-bold text-white mb-8">Our Mission</h2>
                    <div className="text-xl text-gray-400 leading-relaxed space-y-6">
                        <p>
                            At <strong className="text-white">EderSpark</strong>, we're revolutionizing how researchers, students, and innovators interact with scientific knowledge. Born from our firsthand experience with the challenges of academic research, we've created a solution that transforms information overload into actionable insights.
                        </p>
                        <p>
                            By leveraging <strong className="text-white">cutting-edge AI technology</strong>, we're breaking down barriers to scientific understanding and accelerating the pace of discovery. Our platform isn't just a tool—it's a catalyst for scientific progress.
                        </p>
                        <p>
                            We believe that when knowledge becomes more accessible, innovation flourishes. That's why we're committed to building the future of scientific research, one breakthrough at a time.
                        </p>
                    </div>
                </motion.div>
            </motion.div>
            <Footer />
        </motion.div>
    );
};

export default AboutPage;
