import React from "react";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Footer from "../components/Footer";

const steps = [
  {
    title: "Choose Your Model",
    description: "Select from our range of specialized AI models - from Freiya Mini for quick queries to Freiya Nova for advanced research.",
    icon: "fas fa-robot",
    color: "from-blue-500 to-blue-600"
  },
  {
    title: "Input Your Query",
    description: "Enter your research question, upload papers, or provide context for analysis. Our AI understands natural language and academic content.",
    icon: "fas fa-keyboard",
    color: "from-green-500 to-green-600"
  },
  {
    title: "Get Instant Analysis",
    description: "Receive comprehensive analysis, insights, and answers powered by our advanced AI models, complete with citations and sources.",
    icon: "fas fa-bolt",
    color: "from-yellow-500 to-yellow-600"
  },
  {
    title: "Refine & Iterate",
    description: "Ask follow-up questions, dive deeper into specific aspects, or switch between models to get the perfect insights for your needs.",
    icon: "fas fa-sync",
    color: "from-purple-500 to-purple-600"
  }
];

const features = [
  {
    title: "Natural Language Processing",
    description: "Our AI understands complex queries and provides natural, coherent responses.",
    icon: "fas fa-comments"
  },
  {
    title: "Academic Integration",
    description: "Seamlessly analyze research papers, citations, and academic content.",
    icon: "fas fa-book"
  },
  {
    title: "Multiple Model Options",
    description: "Choose from various AI models optimized for different research needs.",
    icon: "fas fa-layer-group"
  },
  {
    title: "Real-time Processing",
    description: "Get instant responses and analysis for your research queries.",
    icon: "fas fa-clock"
  }
];

const HowItWorksPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-black min-h-screen"
    >
      <Navbar />
      
      {/* Hero Section */}
      <section className="pt-32 pb-16 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold text-white mb-6"
          >
            How Freiya Works
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-400 max-w-3xl mx-auto"
          >
            Transform your research process with our advanced AI-powered platform
          </motion.p>
        </div>
      </section>

      {/* Steps Section */}
      <section className="py-16 px-4 bg-gray-900">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative group"
              >
                <div className="bg-gray-800 rounded-xl p-6 h-full transition-transform duration-300 group-hover:-translate-y-2">
                  <div className={`w-12 h-12 rounded-lg bg-gradient-to-r ${step.color} flex items-center justify-center mb-4`}>
                    <i className={`${step.icon} text-white text-xl`}></i>
                  </div>
                  <h3 className="text-xl font-bold text-white mb-3">{step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-white text-center mb-12">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 rounded-xl p-6 backdrop-blur-lg"
              >
                <div className="flex items-start gap-4">
                  <div className="text-2xl text-orange-500">
                    <i className={feature.icon}></i>
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </motion.div>
  );
};

export default HowItWorksPage; 