import React, { useState } from 'react';
import PlatformNavbar from "./PlatformNavbar";
import { motion, AnimatePresence } from "framer-motion";
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  BarChart, Bar, Legend
} from 'recharts';
import { FiCpu, FiDollarSign, FiClock, FiActivity } from 'react-icons/fi';
import PlatformSidebar from "../components/PlatformSidebar";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const mockUsageData = [
  { date: '2024-01', tokens: 125000, cost: 2.50, requests: 500 },
  { date: '2024-02', tokens: 250000, cost: 5.00, requests: 1000 },
  { date: '2024-03', tokens: 180000, cost: 3.60, requests: 720 },
  // Add more mock data as needed
];

// Feature flag - move this to your environment variables or config file later
const PLATFORM_ENABLED = false;

const Platform = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [email, setEmail] = useState('');
  
  const handleBetaSubscribe = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Email to support team
      const emailBody = `
        Beta Access Request
        --------------------------
        Email: ${email}
        Date: ${new Date().toLocaleString()}
      `;

      const emailHtml = `
        <h2>Beta Access Request</h2>
        <p><strong>Email:</strong> ${email}</p>
        <p><strong>Date:</strong> ${new Date().toLocaleString()}</p>
      `;

      // Email to the user
      const userEmailHtml = `
        <!DOCTYPE html>
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
              .container { max-width: 600px; margin: 0 auto; padding: 20px; }
              .header { 
                background-color: #f97316; 
                padding: 20px; 
                border-radius: 8px 8px 0 0;
                text-align: center;
              }
              .content { 
                background-color: #ffffff; 
                padding: 20px; 
                border-radius: 0 0 8px 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              }
              .footer { margin-top: 20px; font-size: 12px; color: #666; }
              .button-container {
                text-align: center;
                margin: 30px 0;
              }
              .button { 
                display: inline-block; 
                padding: 14px 32px; 
                background-color: #f97316; 
                color: white !important; 
                text-decoration: none; 
                border-radius: 8px; 
                font-weight: bold;
                font-size: 16px;
                box-shadow: 0 4px 6px rgba(249, 115, 22, 0.2);
                transition: all 0.3s ease;
                border: 2px solid #f97316;
              }
              .button:hover {
                background-color: #ea580c;
                transform: translateY(-1px);
                box-shadow: 0 6px 8px rgba(249, 115, 22, 0.3);
              }
              a {
                color: #f97316;
                text-decoration: none;
              }
              ul {
                padding-left: 20px;
              }
              li {
                margin-bottom: 8px;
              }
            </style>
          </head>
          <body>
            <div class="container">
              <div class="header">
                <h1 style="color: white; margin: 0;">Welcome to Freiya API Platform Beta!</h1>
              </div>
              <div class="content">
                <h2>Thank you for your interest!</h2>
                <p>We're excited to confirm that we've received your request for beta access to the Freiya Platform.</p>
                
                <h3>What's Next?</h3>
                <p>Our team will review your request and reach out to you soon with more information about:</p>
                <ul>
                  <li>Beta access timeline</li>
                  <li>Special beta tester features</li>
                  <li>Getting started guides</li>
                  <li>Support resources</li>
                </ul>

                <p>In the meantime, you can explore our expected API pricing for each model:</p>
                <div class="button-container">
                  <a href="https://www.ederspark.com/platform/pricing" class="button">View Pricing →</a>
                </div>

                <p>If you have any questions, feel free to reach out to our support team at <a href="mailto:support@ederspark.com">support@ederspark.com</a></p>
                
                <div class="footer">
                  <p>Best regards,<br>The Freiya Team</p>
                  <hr style="border: none; border-top: 1px solid #eee; margin: 20px 0;">
                  <p>This email was sent to ${email} because you requested beta access to the Freiya Platform.</p>
                </div>
              </div>
            </div>
          </body>
        </html>
      `;

      // Send email to support team
      await axios.post('https://sparkai.ederspark.com/api/send-mail', {
        to: 'support@ederspark.com',
        subject: 'Beta Access Request - Platform',
        text: emailBody,
        html: emailHtml
      });

      // Send confirmation email to user
      await axios.post('https://sparkai.ederspark.com/api/send-mail', {
        to: email,
        subject: 'Welcome to Freiya Platform Beta!',
        text: 'Thank you for your interest in Freiya Platform Beta. We\'ll review your request and get back to you soon.',
        html: userEmailHtml
      });

      setSubmitStatus('success');
      setTimeout(() => {
        setSubmitStatus('idle');
        setEmail('');
      }, 2000);
    } catch (error) {
      console.error('Failed to send beta access request:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-black relative"
    >
      {/* Alpha Access Modal */}
      <AnimatePresence>
        {!PLATFORM_ENABLED && (
          <>
            {/* Blur Overlay */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/60 backdrop-blur-md z-[60]"
            />
            
            {/* Modal */}
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="fixed inset-0 flex items-center justify-center z-[70] p-4"
            >
              <div className="w-[95%] sm:w-[90%] max-w-lg">
                <div className="bg-[#0F1629] rounded-xl border border-gray-800 p-4 sm:p-8 text-center">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 bg-orange-500/20 rounded-full flex items-center justify-center mx-auto mb-4 sm:mb-6">
                    <i className="fas fa-rocket text-2xl sm:text-3xl text-orange-500"></i>
                  </div>
                  <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-white mb-3 sm:mb-4">
                    Coming Soon
                  </h2>
                  <p className="text-sm sm:text-base text-gray-400 mb-4 sm:mb-6">
                    Our platform is currently in alpha testing. We're working hard to bring you the best experience possible.
                  </p>
                  <div className="flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-4 mb-6">
                    <button
                      onClick={() => navigate('/home')}
                      className="w-full sm:w-auto px-4 sm:px-6 py-2.5 sm:py-3 bg-orange-500 hover:bg-orange-600 text-white rounded-lg transition-colors duration-300"
                    >
                      Back to Home
                    </button>
                    <button
                      onClick={() => navigate('/platform/pricing')}
                      className="w-full sm:w-auto px-4 sm:px-6 py-2.5 sm:py-3 bg-transparent hover:bg-gray-800 text-orange-400 border border-orange-500/30 rounded-lg transition-colors duration-300 flex items-center justify-center gap-2"
                    >
                      <i className="fas fa-tag text-sm"></i>
                      View API Pricing
                    </button>
                  </div>

                  {/* New Beta Access Form */}
                  <div className="mt-6 sm:mt-8 pt-6 sm:pt-8 border-t border-gray-800">
                    <h3 className="text-base sm:text-lg font-semibold text-white mb-3 sm:mb-4">
                      Get Early Access
                    </h3>
                    <p className="text-sm sm:text-base text-gray-400 mb-4">
                      Subscribe to get notified when beta access becomes available.
                    </p>
                    <form onSubmit={handleBetaSubscribe} className="flex flex-col gap-4">
                      <div className="flex flex-col sm:flex-row gap-2">
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email"
                          className="w-full flex-1 px-4 py-2 bg-black/30 border border-gray-700 rounded-lg text-white placeholder-gray-500"
                          required
                        />
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`w-full sm:w-auto px-6 py-2 rounded-lg transition-colors duration-300 ${
                            isSubmitting
                              ? 'bg-gray-600 cursor-not-allowed'
                              : 'bg-orange-500 hover:bg-orange-600'
                          } text-white`}
                        >
                          {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                        </button>
                      </div>
                      
                      {/* Status Messages */}
                      {submitStatus === 'success' && (
                        <p className="text-green-500 text-sm">Successfully subscribed for beta access!</p>
                      )}
                      {submitStatus === 'error' && (
                        <p className="text-red-500 text-sm">Failed to subscribe. Please try again.</p>
                      )}
                    </form>
                  </div>

                  <p className="text-xs sm:text-sm text-gray-500 mt-4 sm:mt-6">
                    Do you want to use Freiya via web?{' '}
                    <button 
                      onClick={() => navigate('/pricing')}
                      className="text-orange-400 hover:text-orange-300 underline transition-colors duration-300"
                    >
                      Check out sparkai copilot pricing
                    </button>
                  </p>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Existing Platform Content - Added pointer-events-none to all content */}
      <div className={!PLATFORM_ENABLED ? 'pointer-events-none filter blur-[2px]' : ''}>
        <PlatformNavbar />
        <PlatformSidebar />
        
        {/* Main Content - Adjusted margin to account for sidebar */}
        <div className="pt-24 pl-72 pr-8">
          <div className="max-w-7xl mx-auto">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold text-white mb-4"
            >
              API Platform
              <span className="ml-3 text-sm px-3 py-1 bg-orange-500/20 text-orange-400 rounded-full">
                Beta
              </span>
            </motion.h1>
            
            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
              <StatsCard
                icon={<FiCpu />}
                title="Total Requests"
                value="2,220"
                trend="+15.3%"
                positive={true}
              />
              <StatsCard
                icon={<FiDollarSign />}
                title="Total Cost"
                value="$11.10"
                trend="-2.4%"
                positive={false}
              />
              <StatsCard
                icon={<FiClock />}
                title="Avg. Response Time"
                value="238ms"
                trend="-12.5%"
                positive={true}
              />
              <StatsCard
                icon={<FiActivity />}
                title="Success Rate"
                value="99.9%"
                trend="+0.2%"
                positive={true}
              />
            </div>

            {/* Main Content */}
            <div className="mt-12 bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800">
              <div className="flex flex-wrap gap-4 mb-8">
                <TabButton 
                  active={activeTab === 'overview'} 
                  onClick={() => setActiveTab('overview')}
                >
                  Overview
                </TabButton>
                <TabButton 
                  active={activeTab === 'usage'} 
                  onClick={() => setActiveTab('usage')}
                >
                  Usage Analytics
                </TabButton>
                <TabButton 
                  active={activeTab === 'billing'} 
                  onClick={() => setActiveTab('billing')}
                >
                  Billing
                </TabButton>
                <TabButton 
                  active={activeTab === 'api'} 
                  onClick={() => setActiveTab('api')}
                >
                  API Keys
                </TabButton>
              </div>

              {/* Charts Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <ChartCard title="Token Usage Over Time">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={mockUsageData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                      <XAxis dataKey="date" stroke="#666" />
                      <YAxis stroke="#666" />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: 'rgba(0,0,0,0.8)', 
                          border: '1px solid #333',
                          borderRadius: '8px'
                        }} 
                      />
                      <Line 
                        type="monotone" 
                        dataKey="tokens" 
                        stroke="#f97316" 
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </ChartCard>

                <ChartCard title="Requests by Model">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={[
                      { model: 'Mini', value: 300 },
                      { model: 'Focus', value: 450 },
                      { model: 'Pro', value: 700 },
                      { model: 'Nova', value: 200 },
                    ]}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                      <XAxis dataKey="model" stroke="#666" />
                      <YAxis stroke="#666" />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: 'rgba(0,0,0,0.8)', 
                          border: '1px solid #333',
                          borderRadius: '8px'
                        }} 
                      />
                      <Bar dataKey="value" fill="#f97316" />
                    </BarChart>
                  </ResponsiveContainer>
                </ChartCard>
              </div>

              {/* API Key Section */}
              <div className="mt-8 p-6 bg-black/30 rounded-xl border border-gray-800">
                <h3 className="text-xl font-semibold text-white mb-4">API Key</h3>
                <div className="flex items-center gap-4">
                  <input
                    type="password"
                    value="sk-••••••••••••••••••••••••"
                    readOnly
                    className="flex-1 bg-black/50 border border-gray-700 rounded-lg px-4 py-2 text-gray-300"
                  />
                  <button className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors">
                    Generate New Key
                  </button>
                </div>
                <p className="mt-2 text-sm text-gray-400">
                  Keep your API key secure. Never share it publicly or commit it to version control.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const StatsCard = ({ icon, title, value, trend, positive }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800"
  >
    <div className="flex items-center gap-3 mb-4">
      <span className="text-2xl text-orange-500">{icon}</span>
      <h3 className="text-gray-400">{title}</h3>
    </div>
    <div className="flex items-end justify-between">
      <span className="text-2xl font-bold text-white">{value}</span>
      <span className={`text-sm ${positive ? 'text-green-500' : 'text-red-500'}`}>
        {trend}
      </span>
    </div>
  </motion.div>
);

const TabButton = ({ children, active, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
      active 
        ? 'bg-orange-500 text-white' 
        : 'bg-gray-800/50 text-gray-400 hover:bg-gray-800'
    }`}
  >
    {children}
  </button>
);

const ChartCard = ({ title, children }) => (
  <div className="bg-black/30 rounded-xl p-6 border border-gray-800">
    <h3 className="text-lg font-semibold text-white mb-6">{title}</h3>
    {children}
  </div>
);

export default Platform; 