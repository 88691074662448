import React from "react";
import Navbar from "../pages/Navbar.jsx";
import { Box, Typography, Button } from "@mui/joy";
import ContactSection from "../components/ContactSection.jsx";
import Footer from "../components/Footer.jsx";

const Contact = ({ activeLink, setActiveLink }) => {
  return (
    <div
      className="bg-white w-full"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        <ContactSection />
      <Footer />
    </div>
  );
};

export default Contact;
