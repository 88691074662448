import './App.css';
import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Landing from './pages/Landing';
import PaymentForm from './components/Checkout';
import CookiesPolicyPage from './pages/CookiesPolicyPage';
import TermsPage from './pages/TermsPage';
import DisclaimerPage from './pages/DisclaimerPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import PaymentPage from './pages/PaymentPage';
import Dashboard from './pages/Dashboard';
import PricingPage from './pages/PricingPage';
import AboutPage from './pages/AboutPage';
import UniversitiesPage from './pages/UniversitiesPage';
import ResearchCentersPage from './pages/ResearchCentersPage';
import EnterprisesPage from './pages/EnterprisesPage';
import Ederpad from './pages/Ederpad';
import HowItWorksPage from './pages/HowItWorksPage';
import CookieBanner from './components/CookieBanner';
import Platform from './pages/Platform';
import PlatformPricing from './pages/PlatformPricing';


export const MainContext = React.createContext(null);


function App() {
  const [activeLink, setActiveLink] = React.useState("Home");

  // Read param "page" from URL
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page');
    if (page) {
      setActiveLink(page);
    }
    console.log("activeLink", activeLink);
  }, []);

  return (
    <>
    <MainContext.Provider value={null}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/cookie-policy" element={<CookiesPolicyPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/universities" element={<UniversitiesPage />} />
        <Route path="/research-centers" element={<ResearchCentersPage />} />
        <Route path="/enterprises" element={<EnterprisesPage />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/how-it-works" element={<HowItWorksPage />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/platform/pricing" element={<PlatformPricing />} />
      </Routes>
      <CookieBanner />
    </MainContext.Provider>
    </>
  );
}

export default App;
