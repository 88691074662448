import React from "react";
import { motion } from "framer-motion";

const productFeatures = [
  {
    icon: <i className="fas fa-search"></i>,
    title: "AI-Powered Research",
    description: "Leverage advanced AI to quickly find and synthesize information from millions of academic papers."
  },
  {
    icon: <i className="fas fa-bolt"></i>,
    title: "Instant Insights",
    description: "Get immediate answers to complex research questions with peer-reviewed citations."
  },
  {
    icon: <i className="fas fa-link"></i>,
    title: "Contextual Understanding",
    description: "Our AI understands the relationships between concepts across different scientific disciplines."
  },
  {
    icon: <i className="fas fa-chart-line"></i>,
    title: "Accelerated Discovery",
    description: "Identify patterns and connections that would take weeks to discover manually."
  }
];

const ProductSection = () => {
  return (
    <section className="py-16 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-[4.5rem] md:leading-[6rem] font-bold text-white mb-6">
            <span className="text-orange-400 font-semibold">Over 85%</span> time saved with <span className="text-orange-400 font-semibold">Freiya</span>
          </h2>
          <p className="text-gray-300 max-w-3xl mx-auto text-lg">
            Our AI research copilot bridges the gap between information overload and meaningful insights
          </p>
        </motion.div>

        <div className="flex flex-col lg:flex-row gap-12 items-stretch">
          {/* Left column: Product value proposition */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="lg:w-1/2 flex"
          >
            <div className="bg-gradient-to-br from-blue-900/30 to-purple-900/20 border border-blue-800/30 rounded-xl p-8 shadow-lg w-full flex flex-col">
              <h3 className="text-2xl font-bold text-white mb-6">
                Your Research Solution
              </h3>
              
              <div className="space-y-6 flex-grow">
                <div className="flex gap-4">
                  <div className="rounded-full bg-orange-500/20 p-3 h-12 w-12 flex items-center justify-center flex-shrink-0">
                    <i className="fas fa-rocket text-orange-400 text-xl"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium text-lg mb-2">
                      Accelerate Your Research Process
                    </h4>
                    <p className="text-gray-300">
                      Reduce research time by up to 70% by instantly accessing and synthesizing information from millions of scientific papers.
                    </p>
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <div className="rounded-full bg-orange-500/20 p-3 h-12 w-12 flex items-center justify-center flex-shrink-0">
                    <i className="fas fa-brain text-orange-400 text-xl"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium text-lg mb-2">
                      Uncover Hidden Connections
                    </h4>
                    <p className="text-gray-300">
                      Discover relationships between research across different fields that would be impossible to find manually.
                    </p>
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <div className="rounded-full bg-orange-500/20 p-3 h-12 w-12 flex items-center justify-center flex-shrink-0">
                    <i className="fas fa-check-circle text-orange-400 text-xl"></i>
                  </div>
                  <div>
                    <h4 className="text-white font-medium text-lg mb-2">
                      Reliable Research Partner
                    </h4>
                    <p className="text-gray-300">
                      Every answer is backed by peer-reviewed sources with accurate citations, ensuring academic integrity.
                    </p>
                  </div>
                </div>
              </div>
              
              <motion.a 
                href="https://sparkai.ederspark.com"
                className="inline-flex items-center justify-center w-full mt-8 bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white px-6 py-3 rounded-lg font-medium transition-all duration-300 shadow-lg"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <span>Try Freiya Now</span>
                <i className="fas fa-arrow-right ml-2"></i>
              </motion.a>
            </div>
          </motion.div>
          
          {/* Right column: Product features */}
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="lg:w-1/2 flex"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
              {productFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 * index + 0.5 }}
                  className="bg-gradient-to-br from-gray-800/60 to-gray-900/60 backdrop-blur-sm border border-gray-700 rounded-xl p-6 relative overflow-hidden hover:shadow-lg transition-all duration-300"
                >
                  <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-orange-500 to-orange-600/50"></div>
                  <div className="relative z-10">
                    <div className="text-2xl text-orange-500 mb-3">{feature.icon}</div>
                    <h3 className="text-lg font-bold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-300 text-sm">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
        
        {/* Join Leading Researchers section moved here */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
          className="bg-gradient-to-br from-orange-500/10 to-orange-600/5 border border-orange-500/20 rounded-xl p-6 mt-12 max-w-4xl mx-auto"
        >
          <div className="flex items-start gap-4">
            <div className="text-2xl">
              <i className="fas fa-lightbulb text-orange-300"></i>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-white mb-2">
                Join Leading Researchers
              </h4>
              <p className="text-gray-400">
                Researchers from top universities and institutions are already using Freiya to accelerate their work and make breakthrough discoveries.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ProductSection; 